.accordion {
    .accordion-item {
        border-radius: 0;
        border: none;
        padding-bottom: calc-rem(16);
        .accordion-button {
            @extend .text-medium;
            border-radius: 0;
            background-color: $steel;
        }
        .accordion-body {
            background-color: $gray-25;
            padding: calc-rem(16) calc-rem(16) calc-rem(24);
            > * {
                .accordion-content {
                    display: flex;
                    flex-direction: column;
                    gap: calc-rem(16);
                }
            }
        }
        &:last-of-type {
            .accordion-button  {
                border-radius: 0;
            }
        }
    }
    .accordion-button:focus {
        box-shadow: none;
    }
}