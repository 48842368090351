h1 {
    @include fontSize-lineHeight(90, 95px);
    @include font-family(skf-light);
    color: $text-black;
    font-weight: 300;
}

.h1-thin {
    @include font-family(skf-thin);
    font-weight: 100;
}

h2 {
    @include fontSize-lineHeight(60, 63px);
    @include font-family(skf-light);
    color: $text-black;
    font-weight: 300;
} 

.h2-thin {
    @include font-family(skf-thin);
    font-weight: 100;
}

h3 {
    @include fontSize-lineHeight(44, 46px);
    @include font-family(skf-light);
    color: $text-black;
    font-weight: 300;
    @include media-breakpoint-down(lg) {  
        @include fontSize-lineHeight(32, 34px);
    }
}

h4 {
    @include fontSize-lineHeight(32, 34px);
    @include font-family(skf-light);
    color: $text-black;
    font-weight: 300;
}

h5 {
    @include fontSize-lineHeight(24, 25px);
    @include font-family(skf-light);
    color: $text-black;
    font-weight: 300;
}

h6 {
    @include fontSize-lineHeight(20, 21px);
    @include font-family(skf-light);
    color: $text-black;
    font-weight: 300;
}
.h6-medium {
    @include font-family(skf-medium);
    font-weight: 500;
}