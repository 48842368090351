/* SKF Fonts */

@font-face {
    font-family: 'SKF-Helvetica';
    src: url(/assets/fonts/skf-helvetica-neue.woff) format("woff");
    font-display: swap;
  }
  
  @font-face {
      font-family: 'SKF-chevinot-demibold';
      src: url(/assets/fonts/skfchevinot-demibold-webfont.woff2) format("woff2");
      font-display: swap;
  }
  
  @font-face {
      font-family: 'SKF-chevinot-light';
      src: url(/assets/fonts/skfchevinot-light-webfont.woff2) format("woff2");
      font-display: swap;
  }
  
  @font-face {
      font-family: 'SKF-chevinot-lightitalic';
      src: url(/assets/fonts/skfchevinot-lightitalic-webfont.woff) format("woff");
      font-display: swap;
  }
  
  @font-face {
      font-family: 'SKF-chevinot-medium';
      src: url(/assets/fonts/skfchevinot-medium-webfont.woff2) format("woff2");
      font-display: swap;
  }
  
  @font-face {
      font-family: 'SKF-chevinot-mediumitalic';
      src: url(/assets/fonts/skfchevinot-mediumitalic-webfont.woff) format("woff");
      font-display: swap;
  }
  
  @font-face {
      font-family: 'SKF-chevinot-thin';
      src: url(/assets/fonts/skfchevinot-thin-webfont.woff2) format("woff2");
      font-display: swap;
  }