@import 'helpers';

/* Importing Bootstrap SCSS file. */
@import '~bootstrap/scss/bootstrap';

@import './functions';
@import 'fonts';
@import './labels';
@import './print';
@import './tables';
@import './inputs';
@import './headings';
@import './texts';
@import './buttons';
@import './modals';
@import './accordions';
@import './material-icons';
@import './customer-badge-colors';

/* overrides & additions */

input:focus, select:focus, .btn:focus {
    outline-style: none !important;
    box-shadow: none !important;
    border-color: $steel !important;
}

a {
    text-decoration: none;
}

a:hover {
    text-decoration: underline;
    cursor: pointer;
}

.container-fluid {
  padding-right: 105px;
  padding-left: 105px;
}

 input {
      @include font-family(skf-medium);
      @include fontSize-lineHeight(12, 12px);
      border-radius: 0rem!important;
      color: $clay;
      border: 1px solid $gray-500;
    }

    .form-text {
        @include font-family(skf-medium);
    }


    .form-control{
        border-radius: 0px !important;
    }

.form-select {
    border-radius: 0;
    cursor: pointer;
}

.icon {
    filter: invert(92%) sepia(93%) saturate(0%) hue-rotate(202deg) brightness(106%) contrast(106%);
    height:20px;
    width: 20px;
    position: relative;
    top: 50%;

}

.hover-underline:hover {
    cursor: pointer;
    text-decoration: underline;
}

.card-shadow {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

// Instead of importing Font Awesome
.sr-only {
    border: 0;
    clip: rect(0,0,0,0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px
}

/* Custom scollbar style */
/* width */
::-webkit-scrollbar {
    width: 8px;
  }
  
  /* Track */
  ::-webkit-scrollbar-track {
    background: transparent;
  }
  
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: $gray-200;
    border-radius: 8px;
  }
  
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: $gray-300;
  }
