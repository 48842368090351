$fonts: (
  skf-thin: 'SKF-chevinot-thin',
  skf-light: 'SKF-chevinot-light',
  skf-light-italic: 'SKF-chevinot-lightitalic',
  skf-medium: 'SKF-chevinot-medium',
  skf-medium-italic: 'SKF-chevinot-mediumitalic',
  skf-demibold: 'SKF-chevinot-demibold'
);

/* Bootstrap overrides
======================================================*/

$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 1024px,
  xl: 1440px,
  xxl: 1600px
);

$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1260px,
  xxl: 1438px
);

$accordion-icon-color: $night;
$accordion-icon-active-color: $night;

@import "node_modules/bootstrap/scss/functions";
@import "node_modules/bootstrap/scss/variables";