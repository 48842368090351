.done:before {
    content: "done";
}

.search:before {
    content: "search";
}

.expand-less:before {
    content: "expand_less";
}

.expand-more:before {
    content: "expand_more";
}

.info:before {
    content: "info";
}

.warning:before {
    content: "warning";
}

.check-circle:before {
    content: "check_circle";
}

.edit:before {
    content: "edit";
}

.delete:before {
    content: "delete";
}

.update:before {
    content: "update";
}

.date-range:before {
    content: "date_range";
}

.add:before {
    content: "add";
}

.close-icon:before {
    content: "close";
}

.clear:before {
    content: "clear";
}

.attach-file:before {
    content: "attach_file";
}

.save:before {
    content: "save";
}

.shopping-cart:before {
    content: "shopping_cart";
}

.print-icon:before {
    content: "print";
}

.launch-icon:before {
    content: 'launch';
}

.local-offer:before {
    content: "local_offer";
}

.open-new:before {
    content: "open_in_new";
}

.arrow-forward:before {
    content: "arrow_forward";
}

.library-books:before {
    content: "library_books";
}

.calendar-today:before {
    content: "calendar_today";
}

.calendar-month:before {
    content: "calendar_month";
}

.priority-high:before {
    content: 'priority_high';
}

.arrow-back:before {
    content: 'arrow_back';
}

.save-as:before {
    content: 'save_as';
}

.file-download:before {
    content: 'file_download';
}

.refresh:before {
    content: 'refresh';
}

.error-outline:before {
    content: 'error_outline';
}

.send:before {
    content: 'send';
}

.info-outline-icon:before {
    content: 'info_outline_icon';
}

.menu-book:before {
    content: 'menu_book';
}

.campaign:before {
    content: 'campaign';
}

.chat-bubble-outline:before {
    content: 'chat_bubble_outline';
}

.arrow-downward:before {
    content: 'arrow_downward';
}

.arrow-upward:before {
    content: 'arrow_upward';
}

.circle-icon:before {
    content: 'circle';
}

.filter-alt:before {
    content: 'filter_alt';
}

.search-off:before {
    content: 'search_off';
}

.incomplete-circle:before {
    content: 'incomplete_circle';
}

.upload:before {
    content: 'upload';
}

.download:before {
    content: 'download';
}

.logout-icon:before {
    content: 'logout';
}

.public-off:before {
    content: 'public_off';
}

.exit-to-app:before {
    content: 'exit_to_app';
}

.account-circle:before {
    content: 'account_circle';
}

.home-icon:before {
    content: 'home';
}

.add-shopping-cart:before {
    content: 'add_shopping_cart';
}

.approval:before {
    content: 'approval';
}

.arrow-circle-up:before {
    content: 'arrow_circle_up';
}

.arrow-circle-down:before {
    content: 'arrow_circle_down';
}

.shopping-cart-checkout:before {
    content: 'shopping_cart_checkout'
}

.arrow-drop-down:before {
    content: 'arrow_drop_down'
}

.receipt:before {
    content: 'receipt';
}

.hardware:before {
    content: 'hardware'
}
