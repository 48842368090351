// Button padding
.btn-small {
    @extend .text-small;
    padding: calc-rem(4);
    gap: calc-rem(2);
    .material-icons, .material-icons-outlined {
        transform: translateY(3px);
        font-size: calc-rem(16);
        margin-right: 0.25rem;
    }
}

.btn-medium {
    @extend .text-medium;
    padding: calc-rem(8);
    gap: calc-rem(4);
    .material-icons, .material-icons-outlined {
        transform: translateY(3px);
        font-size: calc-rem(18);
        margin-right: 0.25rem;
    }
}

.btn-large {
    @extend .text-medium;
    padding: calc-rem(8) calc-rem(16);
    gap: calc-rem(4);
    .material-icons, .material-icons-outlined {
        transform: translateY(3px);
        font-size: calc-rem(18);
        margin-right: 0.25rem;
    }
}

// Button styles 
.btn-primary {
    @include btn-generate($white, $grass, none);
}

.btn-secondary {
    @include btn-generate($white, $primary-600, none);
    &:disabled {
        background-color: $primary-500;
        pointer-events: none;
    }
}

.btn-neutral {
    @include btn-generate($white, $clay, none);
}

.btn-danger {
    @include btn-generate($white, $danger-600, none);
}

.btn-tertiary {
    @include btn-generate($primary-600, $white, 1px solid $primary-600);
}

.btn-outlined {
    text-transform: uppercase;
    color: $primary-600;
    background: none;
    border: none;
    &:hover:enabled {
        color: $primary-700;
        cursor: pointer;
        text-decoration: underline;
    }
}

// Component buttons
.btn-primary-lg {
    @extend .btn-primary;
    @extend .btn-large;
}
.btn-primary-md {
    @extend .btn-primary;
    @extend .btn-medium;
}
.btn-primary-sm {
    @extend .btn-primary;
    @extend .btn-small;
}

.btn-secondary-lg {
    @extend .btn-secondary;
    @extend .btn-large;
}
.btn-secondary-md {
    @extend .btn-secondary;
    @extend .btn-medium;
}
.btn-secondary-sm {
    @extend .btn-secondary;
    @extend .btn-small;
}

.btn-neutral-lg {
    @extend .btn-neutral;
    @extend .btn-large;
}
.btn-neutral-md {
    @extend .btn-neutral;
    @extend .btn-medium;
}
.btn-neutral-sm {
    @extend .btn-neutral;
    @extend .btn-small;
}

.btn-danger-lg {
    @extend .btn-danger;
    @extend .btn-large;
}
.btn-danger-md {
    @extend .btn-danger;
    @extend .btn-medium;
}
.btn-danger-sm {
    @extend .btn-danger;
    @extend .btn-small;
}

.btn-tertiary-lg {
    @extend .btn-tertiary;
    @extend .btn-large;
}
.btn-tertiary-md {
    @extend .btn-tertiary;
    @extend .btn-medium;
}
.btn-tertiary-sm {
    @extend .btn-tertiary;
    @extend .btn-small;
}

.btn-outlined-lg {
    @extend .btn-outlined;
    @extend .btn-large;
}
.btn-outlined-md {
    @extend .btn-outlined;
    @extend .btn-medium;
}
.btn-outlined-sm {
    @extend .btn-outlined;
    @extend .btn-small;
}

.add-to-cart-md {
    min-width: calc-rem(250);
    @extend .btn-secondary-lg;
    &:disabled {
        background-color: $gray-100 !important;
        color: $gray-500 !important;
    }
    &.add-feedback {
        background-color: $primary-400 !important;
        color: $white !important;
    }
}
.add-to-cart-sm {
    min-width: calc-rem(120);
    padding-top: calc-rem(6) !important;
    padding-bottom: calc-rem(6) !important;
    @extend .btn-secondary-sm;
    &:disabled {
        background-color: $gray-100 !important;
        color: $gray-500 !important;
    }
    &.add-feedback {
        background-color: $primary-400 !important;
        color: $white !important;
    }
}

.add-to-cart-partial-md {
    min-width: clac-rem(131);
    @extend .btn-tertiary-md;
    &:disabled {
        background-color: $gray-100 !important;
        border: none;
        color: $gray-500 !important;
    }
    &.add-feedback {
        border: 1px solid $primary-400 !important;
        color: $primary-400 !important;
    }
}
.add-to-cart-partial-sm {
    min-width: calc-rem(120);
    padding-top: calc-rem(6) !important;
    padding-bottom: calc-rem(6) !important;
    @extend .btn-tertiary-sm;
    &:disabled {
        background-color: $gray-100 !important;
        border: none;
        color: $gray-500 !important;
    }
    &.add-feedback {
        border: 1px solid $primary-400 !important;
        color: $primary-400 !important;
    }
}

