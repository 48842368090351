.text-large {
    @include font-family(skf-medium);
    color: $text-black;
    font-size: calc-rem(18);
    font-weight: 500;
}

.text-medium {
    @include font-family(skf-light);
    color: $text-black;
    font-size: calc-rem(16);
    font-weight: 300;
}

.text-small { 
    @include font-family(skf-light);
    color: $text-black;
    font-size: calc-rem(14);
    font-weight: 300;
}

.text-extra-small {
    @include font-family(skf-medium);
    color: $text-black;
    font-size: calc-rem(12);
    font-weight: 500;
}

p {
    margin-bottom: 0;
}