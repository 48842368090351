// usage for modals that doesn't make use of NgbModal
body.modal-open {
    overflow: hidden;
}

.modal-base {
    .modal-content {
        padding: calc-rem(64) calc-rem(32);

        @include media-breakpoint-up(sm) {
            padding: calc-rem(48) calc-rem(64);
        }

        border-radius: 0rem;
        gap: calc-rem(40);
        border: 0;

        .modal-header,
        .modal-footer,
        .modal-body {
            border: none !important;
            padding: 0;
        }

        .modal-footer {
            @include media-breakpoint-down(lg) {
                flex-direction: column-reverse;
                gap: 8px;

                >button {
                    width: 100%;
                    margin: 0 !important;
                }
            }
        }

        .modal-footer>* {
            margin: 0;
        }

        .modal-title {
            @include fontSize-lineHeight(32, 34px);
            @include font-family(skf-light);
            color: $text-black;
            font-weight: 300;
        }

        .modal-body {
            @extend .text-large;
        }

        .btn-close {
            opacity: 1;
            font-size: cacl-rem(20);
            color: $night;
        }

        .btn-close:focus {
            box-shadow: none;
        }
    }
}

@mixin mobile-modals {
    @include media-breakpoint-up(xs) {
        width: 100%;
    }

    @include media-breakpoint-up(md) {
        max-width: 100% !important;
        width: 80%;
    }

    @include media-breakpoint-down(md) {
        margin: 0;
        padding: 0;
        min-height: 100%;
        height: 0;
        max-width: 100% !important;
    }
}

@mixin modal-height {
    min-height: 100%;

    @include media-breakpoint-up(lg) {
        height: auto;
    }
}

.modal-small {
    @extend .modal-base;

    .modal-content {
        @include modal-height();
    }

    .modal-dialog {
        @include mobile-modals();

        @include media-breakpoint-up(lg) {
            max-width: calc-rem(596) !important;
        }

        @media print {
            width: 100%;
        }
    }
}

.modal-medium {
    @extend .modal-base;

    .modal-content {
        @include modal-height();
    }

    .modal-dialog {
        @include mobile-modals();

        @include media-breakpoint-up(lg) {
            max-width: calc-rem(841);
        }

        @media print {
            width: 100%;
        }
    }
}

.modal-large {
    @extend .modal-base;

    .modal-content {
        @include modal-height();
    }

    .modal-dialog {
        @include mobile-modals();

        @include media-breakpoint-up(lg) {
            width: calc-rem(1024);
        }

        @media print {
            width: 100%;
        }
    }

    .modal-content {
        .btn-close {
            font-size: cacl-rem(24) !important;
        }
    }
}

.modal-overlay {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, .25);
    z-index: 2;
    cursor: pointer;
}

// Slide out modals, used for filters in order book and invoices
@import 'src/styles/helpers';

.filter-modal {
    padding-right: 0px !important;

    .modal-dialog {
        min-height: 100%;
        margin: 0 0 0 auto;
        background: $white;
        max-width: 28%;
        @include media-breakpoint-down(xl) {
            max-width: 35%;
        }

        @include media-breakpoint-down(lg) {
            max-width: 50%;
        }

        @include media-breakpoint-down(sm) {
            max-width: 100%;
        }
    }

    .modal.fade.show .modal-dialog .modal-body {
        overflow-y: auto;
        overflow-x: hidden;
    }

    .modal-content {
        padding: calc-rem(112) calc-rem(40) calc-rem(0) calc-rem(16);
        border: 0;
        gap: calc-rem(40);

        @include media-breakpoint-down(lg) {
            padding-top: calc-rem(32);
        }
    }

    .modal-header,
    .modal-body {
        padding: calc-rem(0) calc-rem(8);
        border-bottom: none;
    }

    .btn-close {
        opacity: 1;
        font-size: calc-rem(14);
        color: $night;
    }

    .btn-close:focus {
        box-shadow: none;
    }

    .modal-body,
    .form-controls {
        display: flex;
        flex-direction: column;
        gap: calc-rem(40);
    }
}

.date-input-width {
    width: 45%;
    position: relative;
}