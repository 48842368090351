.sfm-table-container {
    .table-header {
        background-color: $gray-300;
        th, .header-column {
            position: -webkit-sticky;
            position: sticky;
            top: -1px;
            z-index: 1;
            background-color: $gray-300;
            padding: 10px 10px;
            cursor: pointer !important;
            height: calc-rem(44);
            .material-icons {
                font-size: calc-rem(18);
                vertical-align: -3px;
            }
        }
    }

    .table td, .table.td, .column {
        border-top: none;
        padding: 10px 0px 10px 0px;
        border-bottom: 1px solid $gray-300;
    }

    th,
    td, .th, .column {
        @extend .text-medium;
        letter-spacing: 0;
    }
}

.sfm-floating-table-body {
    @extend .sfm-table-container;
}