/**
 * Inputs
 *
 * Custom Inputs are defined here.
*/

// Borders
.border-error,
.border-error:focus {
    border: 1px solid $danger-600;
    border-color: $danger-600 !important;
}

.border-info,
.border-info:focus {
    border: 1px solid $minor-500;
    border-color: $minor-500 !important;
}



.border-warning,
.border-warning:focus {
    border: 1px solid $warning-500;
    border-color: $warning-500 !important;
}

// Error messages 
.message-error {
    @extend .text-small;
    color: $danger-600 !important;
    line-height: 14.78px;
}

.message-info {
    @extend .text-small;
    line-height: 14.78px;
    
    .availability-link {
        cursor: pointer;
    }
    u {
        color: $minor-500 !important;
        text-decoration: none;
        cursor: pointer;
    }
}

.message-success {
    @extend .text-small;
    color: $grass !important;
    line-height: 14.78px;
}

.message-warning {
    @extend .text-small;
    color: $warning-500 !important;
    line-height: 14.78px;

    .material-icons {
        color: $warning-500;
        font-size: 20px;
        @include translateY(28%);
    }
}